import ReactTypingEffect from 'react-typing-effect'
import { Linkedin, Github, Camera, Git } from 'react-bootstrap-icons'
import ParticlesBg from 'particles-bg'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/home.css'

const Home = () => {

    return (
            <div className="home-page">
                {/*Particles Bg Component and Params */}
                <ParticlesBg num={50} type="circle" bg={true} />

                            <div className="hero">
                                <AnimationOnScroll duration={1.5} animateIn='animate__bounceInLeft' delay={400}>
                                    <h1>Isaac Afrifa</h1>
                                </AnimationOnScroll>
                                <AnimationOnScroll duration={1.2} animateIn='animate__bounceInRight' delay={400}>
                                    <h2>Software Engineer</h2>
                                </AnimationOnScroll>
                                <AnimationOnScroll duration={1.2} animateIn='animate__bounceInLeft' delay={400}>
                                    <h3>
                                        <ReactTypingEffect
                                            staticText="I"
                                            text={["build software", "write blogs", "take photos"]}
                                            speed={200}
                                            typingDelay={1800}
                                            eraseDelay={1000}
                                            eraseSpeed={100}
                                        />
                                    </h3>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn='animate__zoomIn' delay={1000}>
                                    <div className="socials">
                                        <a className="icon-box" title="LinkedIn" href="https://www.linkedin.com/in/isaacafrifa/"><Linkedin size={24} /></a>
                                        <a className="icon-box" title="Github" href="https://github.com/isaacafrifa"><Github size={24} /></a>
                                        <a className="icon-box" title="BitBucket" href="https://bitbucket.org/mrblo"><Git size={24} /></a>
                                        <a className="icon-box" title="Flickr" href="https://www.flickr.com/photos/afrifa/"><Camera size={24} /></a>
                                    </div>
                                </AnimationOnScroll>
                            </div>     
            </div>
    );
}

export default Home;