import { useEffect, useState } from "react"
import { ArrowUpCircleFill } from "react-bootstrap-icons";
import '../assets/css/scrollToTop.css'


const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowScroll(true)
      } else {
        setShowScroll(false)
      }
    });
  }, []);


  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="top-to-btm">
      <ArrowUpCircleFill color="#18d26e" className="arrow-icon-position arrow-icon-style"
        onClick={goToTop}
        style={{ display: showScroll ? 'flex' : 'none' }} />
    </div>
  );

}

export default ScrollToTop;