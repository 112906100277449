import { Navbar, Nav, Container } from 'react-bootstrap';
import { withRouter } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { ABOUT_URL, CONTACT_URL, HOME_URL, PORTFOLIO_URL, PUBLICATIONS_URL, RESUME_URL } from '../constants/urls';
import '../assets/css/navbar.css';

const Navigation = () => {
    // const { location } = props;
    // console.log(location);
    return (
        <header className="row">
            <div className="col-md-12">
                <Navbar className="navbar"
                    collapseOnSelect
                    fixed="top"
                    expand='lg' bg='' variant='dark'>
                    <Container fluid>

                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse id='basic-navbar-nav'>
                            <Nav className="ml-auto" activeKey={window.location.pathname}>

                                <LinkContainer exact to={HOME_URL}>
                                    <Nav.Link>Home</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={ABOUT_URL}>
                                    <Nav.Link>About</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={RESUME_URL}>
                                    <Nav.Link>Resume</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={PORTFOLIO_URL}>
                                    <Nav.Link>Portfolio</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={PUBLICATIONS_URL}>
                                    <Nav.Link>Publications</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={CONTACT_URL}>
                                    <Nav.Link>Contact</Nav.Link>
                                </LinkContainer>

                            </Nav>
                        </Navbar.Collapse>

                    </Container>
                </Navbar>
            </div>
        </header>
    );
}

export default withRouter(Navigation);