export const flickrImages = [
    {
        src: "https://live.staticflickr.com/65535/49884113653_d6dd3a846e_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49884113653_d6dd3a846e_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49890330218_1af2195b8d_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49890330218_1af2195b8d_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49898970903_1360de6901_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49898970903_1360de6901_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49891105838_c683f8d476_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49891105838_c683f8d476_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49884635336_a3b15c0672_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49884635336_a3b15c0672_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49891158552_84380885a6_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49891158552_84380885a6_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49884641361_6cb1db01fb_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49884641361_6cb1db01fb_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49884938997_e6392219eb_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49884938997_e6392219eb_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49891181307_f625e1220d_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49891181307_f625e1220d_c.jpg`
    },
    {
        src: "https://live.staticflickr.com/65535/49884636556_64777721da_c.jpg",
        alt: `photo gallery`,
        thumbnail: `https://live.staticflickr.com/65535/49884636556_64777721da_c.jpg`
    }

];
