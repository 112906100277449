import React from 'react'
import { TagCloud } from 'react-tagcloud'

function ToolsTagCloud() {
    const data = [
        { value: 'Mockito', count: 25 },
        { value: 'RabbitMQ', count: 22 },
        { value: 'BitBucket', count: 28 },
        { value: 'Materialize', count: 22 },
        { value: 'Heroku', count: 18 },
        { value: 'GraphQL', count: 24 },
        { value: 'Github', count: 23 },
        { value: 'REST', count: 24 },
        { value: 'Microservices', count: 21 },
        { value: 'Strapi CMS', count: 15 },
        { value: 'PostgreSQL', count: 22 },
        { value: 'Android', count: 25 },
        { value: 'Redux', count: 18 },
        { value: 'Bootstrap', count: 23 },
        { value: 'JUnit', count: 21 },
        { value: 'GitHub Actions', count: 18 },
        { value: 'Cucumber', count: 21 },
        { value: 'Maven', count: 25 },
        { value: 'Postman', count: 18 },
        { value: 'Jira', count: 15 },
        { value: 'Docker', count: 20 },
        { value: 'Jenkins', count: 21 }
      ]

// custom renderer is function which has tag, computed font size and
// color as arguments, and returns react component which represents tag
const customRenderer = (tag, size, color) => (
    <span
      key={tag.value}
      style={{
        animation: 'blinker 3s linear infinite',
        animationDelay: `${Math.random() * 2}s`,
        fontSize: `${size / 2}em`,
        border: `1px solid ${color}`,
        margin: '3px',
        padding: '3px',
        display: 'inline-block',
        color: 'white',
      }} >
      {tag.value}
    </span>
  )

    return (
        <div>
       <TagCloud tags={data} minSize={1} maxSize={3} renderer={customRenderer} />      
        </div>
    )
}

export default ToolsTagCloud
