import { Navbar, Nav, Container } from 'react-bootstrap';
import '../assets/css/footer.css'

const Footer = () => {
    return (
        <footer>
            <div className="row">
                <div className="col-md-12">
                    <Navbar className="footer-navbar"
                        fixed="bottom"
                        expand='lg' bg='' variant='dark'>
                        <Container fluid>
                            <Nav className="mr-auto">
                                <p>&copy; 2023 Isaac Afrifa</p>
                            </Nav>

                        </Container>
                    </Navbar>
                </div>
            </div>
        </footer>
    );
}

export default Footer;