import { Roller } from 'react-spinners-css'
import { useSpring, animated } from 'react-spring'
import '../assets/css/loading.css'

const Loading = () => {

  const loaderProps = useSpring({
    to: { opacity: 0.98 }, //end with light fade  
    from: { opacity: 1 },
    delay: 2500 //make sure delay is almost same as isLoading time so it blends in nicely
  })

  return (
    <animated.div style={loaderProps}>
      <div className="loaderBackground">
        <Roller color="#f2f2f2" size="150px" />
      </div>
    </animated.div>
  );
}

export default Loading;