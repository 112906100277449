import { Container, Row, Col } from 'react-bootstrap'
import Avatar from '../assets/images/avatar.jpg'
import Tilt from 'react-parallax-tilt';
import { StarFill, Star } from 'react-bootstrap-icons'
import ScrollToTop from '../components/scrollToTop'
import ToolsTagCloud from '../components/toolsTagCloud'
import TechTagCloud from '../components/techTagCloud';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Roller } from 'react-spinners-css'
import { useState } from 'react';
import { useSpring, animated } from 'react-spring'
import '../assets/css/about.css';

const About = () => {

  const loaderProps = useSpring({
    to: { opacity: 0.5 }, //end with light fade  
    from: { opacity: 1 }
  })
  const avatarProps = useSpring({
    to: [{ opacity: 0.2 }, { opacity: 1 }],
    from: { opacity: 0 }
  })

  const [imageLoading, setImageLoading] = useState(true)
  const handleImageLoaded = () => {
    setImageLoading(false)
  }

  return (
    <div className='about'>
      <Container fluid>
        <div className="aboutMeSection">

          {/* Scroll Button */}
          <ScrollToTop />

          {/* About Me Section */}
          <AnimationOnScroll animateIn='animate__zoomIn' duration={1.1}>
            <Row>
              <Col md={6}>
                <Tilt
                  className="tilt-img" tiltMaxAngleX={35}
                  tiltMaxAngleY={35} perspective={900}
                  scale={0.9}
                  transitionSpeed={2000}
                  gyroscope={true}>

                  <div>
                    {imageLoading && (
                      <animated.div style={loaderProps}
                        className="avatarLoader">
                        <Roller color="#f2f2f2" size="20px" />
                      </animated.div>
                    )}
                    <animated.div style={avatarProps}>
                      <img src={Avatar} width="480" height="480" className="img-fluid avatar" alt="avatar" loading="lazy" onLoad={handleImageLoaded} onError={handleImageLoaded} />
                    </animated.div>
                  </div>

                </Tilt>
              </Col>

              <Col md={6} >
                <div className="aboutDetails">
                  <div className="header">
                    <h1>About Me</h1>
                  </div>

                  <p>Hello there! I'm Isaac Afrifa, a seasoned Software Engineer with over 8 years of experience spanning diverse industries in Ghana, the UK, and the EU.
                  I'm AWS-certified and a recognized published researcher, with expertise in implementing technology-driven solutions that make a tangible impact.</p>
                  <p className="lastParagraph">Currently, I'm working as an Application Development Team Lead at Accenture, and always looking to discover, inspire, and 
                  create high-quality innovations.</p>
                  <p>When I'm not working, I'm probably watching a good game of football especially Chelsea matches😉. I also enjoy listening to different genres of music 🎧, working out 🏋🏽‍♂️,
                    and travelling around the world 🌍.
                  </p>
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </div>
        

        {/* TECHNOLOGIES */}
        <div className="techdiv">
          <AnimationOnScroll animateIn='animate__zoomIn' duration={1.1}>
        <Container fluid>
            <Row>
              <Col md={8} lg={{span: 4, offset:4}}>
                <div>
                  <h3 style={{ marginBottom: "1rem" }}>technologies</h3>
                  <p>Here are my go-to and recent tech stack</p>
                  <div className="tech-logos">
                    <TechTagCloud />
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
          </AnimationOnScroll>
        </div>


        {/* OTHER TECH & TOOLS*/}
        <div className="othertech">

          <Row>
            <Col lg={6}>
              <AnimationOnScroll animateIn='animate__zoomIn' duration={1.1}>
                <div className="techdiv">
                  <h3 style={{ marginBottom: "1rem" }}>other tech &amp; tools</h3>
                  <div className="tech-logos">
                    <ToolsTagCloud />
                  </div>
                </div>
              </AnimationOnScroll>
            </Col>

            {/* LANGUAGES */}
            <Col lg={6}>
              <AnimationOnScroll animateIn='animate__zoomIn' duration={1.1}>
                <div className="languages-section">
                  <h3>languages</h3>
                  <Row style={{ marginTop: "1.5rem" }}>
                    <Col>
                      English
                      <div className="tech-score" style={{ marginTop: "0.5em" }}>
                        <StarFill size={16} className="filled" />
                        <StarFill size={16} className="filled" />
                        <StarFill className="filled" size={16} />
                      </div>
                    </Col>
                    <Col>
                      French
                      <div className="tech-score" style={{ marginTop: "0.5em" }}>
                        <StarFill size={16} className="filled" />
                        <Star size={16} />
                        <Star size={16} />
                      </div>
                      <span style={{ fontSize: ".8rem" }}>(Je l'apprends)</span>
                    </Col>
                  </Row>
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </div>

      </Container>
    </div>

  );
}

export default About;