import React from 'react'
import { TagCloud } from 'react-tagcloud'

function TechTagCloud() {
  const data = [
    { value: 'Java', count: 30 },
    { value: 'MySQL', count: 23 },
    { value: 'HTML', count: 22 },
    { value: 'Spring Boot', count: 27 },
    { value: 'CSS', count: 20 },
    { value: 'ReactJS', count: 25 },
    { value: 'JavaScript', count: 22 },
    { value: 'AWS', count: 25 },
    { value: 'MongoDB', count: 20 },
    { value: 'CI/CD', count: 28 }
  ]

  // custom renderer is function which has tag, computed font size and
  // color as arguments, and returns react component which represents tag
  const customRenderer = (tag, size, color) => (
    <span
      key={tag.value}
      style={{
        animation: 'bounce 3s linear infinite',
        animationDelay: `${Math.random() * 2}s`,
        fontSize: `${size / 2}em`,
        border: `1px solid ${color}`,
        margin: '3px',
        marginTop: '12px',
        padding: '3px',
        display: 'inline-block',
        color: 'white',
      }} >
      {tag.value}
    </span>
  )

  return (
    <div>
      <TagCloud tags={data} minSize={1} maxSize={3} shuffle={false} renderer={customRenderer} />
    </div>
  )
}

export default TechTagCloud
