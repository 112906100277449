import { useRef } from 'react'
import { ArrowDownCircleFill, Link45deg } from 'react-bootstrap-icons'
import { Badge, Button, Container } from 'react-bootstrap'
import ScrollToTop from '../components/scrollToTop'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/publications.css'

export default function Publications() {
    const titleRef = useRef()
    const handleScrollDownClick = () => {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <main>

            <section className="page-one">
                <div className="banner">
                    <AnimationOnScroll animateIn='animate__flipInY'>
                        <h1>Publications</h1>
                    </AnimationOnScroll>
                </div>

                <div>
                    <ArrowDownCircleFill className="scrollDown" onClick={handleScrollDownClick} color="#18d26e" size={48} />
                </div>
            </section>

            <br />
            <span ref={titleRef}></span>
            <ScrollToTop />

            <section className="page-two">
                <Container fluid="md">
                    <div className="publications">
                        <div className="flex-container">

                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://cdn.hashnode.com/res/hashnode/image/upload/v1701816377632/145ebb5a-fa53-44a9-a975-46db7e53321f.jpeg)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">rest vs graphql: the unmasking</h1>
                                                <p className="card-text">
                                                Ever tried GraphQL? Explore REST and GraphQL: their unique features, pros, cons, and use cases...</p>
                                                <Button className="button btn-success" href="https://iafrifa.hashnode.dev/rest-vs-graphql-the-unmasking" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="primary" className="badge">api</Badge>
                                                    <Badge variant="secondary" className="badge">blog</Badge>
                                                    <Badge variant="warning" className="badge">architecture</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>

                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://cdn.hashnode.com/res/hashnode/image/upload/v1697753187583/823c6674-e8d7-4113-9ce3-346e112bf849.jpeg)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">exploring testcontainers: my experience</h1>
                                                <p className="card-text">
                                                Time to forget about in-memory databases during testing. Try Testcontainers...</p>
                                                <Button className="button btn-success" href="https://iafrifa.hashnode.dev/exploring-testcontainers-my-experience" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="success" className="badge">testing</Badge>
                                                    <Badge variant="light" className="badge">blog</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>

                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://cdn.hashnode.com/res/hashnode/image/upload/v1692742001711/72c9bc87-e833-43cb-9c26-d2be4fd0aa3b.jpeg)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">Communication: the overlooked cornerstone of tech</h1>
                                                <p className="card-text">
                                                This article talks about communication and its pivotal role in successful IT projects...</p>
                                                <Button className="button btn-success" href="https://iafrifa.hashnode.dev/communication-the-overlooked-cornerstone-of-technology" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="primary" className="badge">hashnode</Badge>
                                                    <Badge variant="success" className="badge">blog</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
     
                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://cdn.hashnode.com/res/hashnode/image/upload/v1667172167446/1Ix4gyywk.jpg)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">&lt;&gt;Mental Health&lt;/&gt;</h1>
                                                <p className="card-text">
                                                This article talks about mental health, imposter syndrome, and some resolutions...</p>
                                                <Button className="button btn-success" href="https://iafrifa.hashnode.dev/mental-health" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="primary" className="badge">mental-health</Badge>
                                                    <Badge variant="warning" className="badge">hashnode</Badge>
                                                    <Badge variant="success" className="badge">blog</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>

                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://wordsrated.com/wp-content/uploads/2022/02/Number-of-Books-Published-Per-Year.jpg)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">an evaluation of data erasing tools</h1>
                                                <p className="card-text">
                                                    This paper analyzes the efficiency of a number of these tools in performing erasures on...</p>
                                                <Button className="button btn-success" href="https://commons.erau.edu/jdfsl/vol15/iss1/2/" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="primary" className="badge">academic-paper</Badge>
                                                    <Badge variant="secondary" className="badge">digital forensics</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>

                            <div className="col-12 col-md-4">
                                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                                    <div className="mycard mb-3">
                                        <div className="mycard-img" style={{ backgroundImage: "url(https://cdn.hashnode.com/res/hashnode/image/upload/v1615298615390/s0KV8RBMx.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress)" }}>
                                            <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                                                <h1 className="card-title">code smells: naming</h1>
                                                <p className="card-text">
                                                    This article aims to discuss Naming in relation to good coding practices...</p>
                                                <Button className="button btn-success" href="https://iafrifa.hashnode.dev/code-smells-naming" target="_blank" rel="noopener noreferrer">
                                                    <Link45deg /> read more...</Button>
                                                <div className="project-badges">
                                                    <Badge variant="success" className="badge">blog</Badge>
                                                    <Badge variant="primary" className="badge">code-smells</Badge>
                                                    <Badge variant="light" className="badge">hashnode</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>

        </main>
    )
}
