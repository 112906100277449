
import ScrollToTop from '../components/scrollToTop'
import { useRef } from 'react'
import { ArrowDownCircleFill, Link45deg } from 'react-bootstrap-icons'
import { Badge, Button, Container } from 'react-bootstrap'
import { flickrImages } from '../components/flickrImages'
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/portfolio.css'

const Portfolio = () => {

  const titleRef = useRef()
  const handleScrollDownClick = () => {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <main>
      <section className="page-one">
        <div className="banner">
          <AnimationOnScroll animateIn='animate__flipInY'>
            <h1>Portfolio</h1>
          </AnimationOnScroll>
        </div>

        <div>
          <ArrowDownCircleFill className="scrollDown" onClick={handleScrollDownClick} color="#18d26e" size={48} />
        </div>
      </section>


      <span ref={titleRef}></span>
      <ScrollToTop />


      <section className="page-two">
        <AnimationOnScroll animateIn='animate__fadeInLeft'
          animateOut='animate__fadeOutLeft'>
          <h1>featured projects</h1>
          <p>Some of my software projects</p>
        </AnimationOnScroll>

        <div className="software-projects">
          <Container fluid>

            <div className="flex-container">
              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://github.com/kwabenamarmo/renoir/blob/main/assets/images/background.jpg?raw=true)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">renoir</h1>
                        <p className="card-text">
                          A restaurant website
                        </p>
                        <Button className="button btn-success" href="https://renoir.netlify.app/" target="_blank" rel="noopener noreferrer">
                          <Link45deg /> view site
                        </Button>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">react js</Badge>
                          <Badge variant="info" className="badge">s3</Badge>
                          <Badge variant="dark" className="badge">spring boot</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://play-lh.googleusercontent.com/YT7k39jFoU2UAFCGd7YlGB1_PZ6DcwCnfBn7vIWWV9cr7YNK7DkLmxuJ6L5mouNthDQ=w3360-h1942-rw)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">read'e</h1>
                        <p className="card-text">
                          An Android News App serving the latest headlines and articles.</p>
                        <Button className="button btn-success" href="https://play.google.com/store/apps/details?id=com.blo.reade" target="_blank" rel="noopener noreferrer">
                          <Link45deg /> view app
                        </Button>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">java</Badge>
                          <Badge variant="danger" className="badge">kotlin</Badge>
                          <Badge variant="success" className="badge">xml</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">

                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://marvel-b1-cdn.bc0a.com/f00000000075552/www.perforce.com/sites/default/files/image/2019-01/image-blog-what-code-quality.jpg)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">Sinpoa Crypto Exchange</h1>
                        <p className="card-text">
                          A cryptocurrency exchange website</p>

                        <Button className="button btn-success" href="https://sinpoa.com/" target="_blank" rel="noopener noreferrer">
                          <Link45deg /> view site
                        </Button>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">spring boot</Badge>
                          <Badge variant="secondary" className="badge">jivo</Badge>
                          <Badge variant="warning" className="badge">nuxt js</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>


              </div>

              <div className="col-12 col-md-4">


                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{
                      backgroundImage:
                        "url(https://marvel-b1-cdn.bc0a.com/f00000000075552/www.perforce.com/sites/default/files/image/2019-01/image-blog-what-code-quality.jpg)"
                    }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">ordermania</h1>
                        <p className="card-text">
                          Microservice backend services that implements an inventory management system
                        </p>
                        <div className="project-badges">
                          <Badge variant="warning" className="badge">java</Badge>
                          <Badge variant="info" className="badge">docker</Badge>
                          <Badge variant="success" className="badge">rabbitmq</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://bytebucket.org/mrBlo/portfolio/raw/17adb400677bed0882b6c1520274a1f3ef2bc951/app_image.png?token=821169f4f89e1cfd1a9d8d610dd4b3c8cd2a25d2)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">portfolio</h1>
                        <p className="card-text">
                          My personal portfolio website
                        </p>
                        <Button className="button btn-success" href="#" target="_blank" rel="noopener noreferrer">
                          <Link45deg /> view site
                        </Button>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">react js</Badge>
                          <Badge variant="danger" className="badge">bootstrap</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://github.com/isaacafrifa/upt/raw/main/app_screenshot.png)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">Agyenkwa Investment</h1>
                        <p className="card-text">
                          An investment application.
                        </p>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">react js</Badge>
                          <Badge variant="secondary" className="badge">spring boot</Badge>
                          <Badge variant="warning" className="badge">html</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://bitbucket.org/mrBlo/blogfrontend/raw/40512e0f24bfdbb095579af41f7e77b7ac7ad0c5/app_image.png)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">blog website</h1>
                        <p className="card-text">
                          A blog application
                        </p>
                        <Button className="button btn-success" href="https://selimvanlare.netlify.app/" target="_blank" rel="noopener noreferrer">
                          <Link45deg /> view site
                        </Button>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">react js</Badge>
                          <Badge variant="info" className="badge">strapi</Badge>
                          <Badge variant="dark" className="badge">heroku</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-md-4">
                <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5}>
                  <div className="mycard mb-3">
                    <div className="mycard-img" style={{ backgroundImage: "url(https://bitbucket.org/mrBlo/congressapp/raw/fe04d52deeda962ffc223da34fe48bcdbeb2e699/app/src/main/res/drawable/congress.jpg)" }}>
                      <div className="mycard-img-overlay d-flex flex-column justify-content-between">
                        <h1 className="card-title">coc congress app</h1>
                        <p className="card-text">
                          The official 2016 Students Conference android app of the Church of Christ, Ghana
                        </p>
                        <div className="project-badges">
                          <Badge variant="primary" className="badge">java</Badge>
                          <Badge variant="danger" className="badge">android</Badge>
                          <Badge variant="info" className="badge">gradle</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

            </div>

          </Container>
        </div>


        <div className="photography-projects">
          <AnimationOnScroll animateIn='animate__fadeInLeft'
            animateOut='animate__fadeOutLeft'>
            <h1>photography portfolio</h1>
            <p>Some of my work</p>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn='animate__zoomIn' duration={1.5} animateOut='animate__zoomOut'>
            <Container fluid>
              <Carousel className='photos' images={flickrImages} hasThumbnails={true} isAutoPlaying={true} shouldMinimizeOnClick={true} hasIndexBoard={false}
                shouldMinimizeOnSwipeDown={true} shouldMaximizeOnClick={true} />
            </Container>
          </AnimationOnScroll>
        </div>

      </section>

    </main>
  );
}

export default Portfolio;