import { useState } from "react"
import Home from './pages/home'
import About from './pages/about'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Navigation from './components/navbar'
import Page404 from './pages/Page404'
import Resume from './pages/resume'
import Portfolio from './pages/portfolio'
import Publications from './pages/publications'
import Contact from './pages/contact'
import LoadingComponent from './pages/loading'
import {
  HOME_URL, ABOUT_URL, RESUME_URL, PORTFOLIO_URL,
  PUBLICATIONS_URL, CONTACT_URL
} from './constants/urls'
import Footer from "./components/footer"

function App() {

  const [isLoading, setIsLoading] = useState(true)

  //set isLoading off
  setTimeout(() => {
    setIsLoading(false)
  }, 3000);

  return (
    isLoading ? (
      <LoadingComponent />
    ) :

      (
        <Router>
          <div className="app">
            <Navigation />
            <Switch>
              <Route exact path={HOME_URL}>
                <Home />
              </Route>
              <Route exact path={ABOUT_URL}>
                <About />
              </Route>
              <Route exact path={RESUME_URL}>
                <Resume />
              </Route>
              <Route exact path={PORTFOLIO_URL} component={Portfolio} />
              <Route exact path={PUBLICATIONS_URL} component={Publications} />
              <Route exact path={CONTACT_URL} component={Contact} />
              <Route path="*">
                <Page404 />
              </Route>
            </Switch>
            <Footer />
          </div>
        </Router>

      )
  );
}

export default App;
