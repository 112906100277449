import { Container, Button, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useState } from "react"
import '../assets/css/contactForm.css'

const ContactForm = () => {

    const [isLoading, setLoading] = useState(false);
    const { register, errors, handleSubmit, reset } = useForm();
    const toastifySuccess = () => {
        toast('Message Sent👌🏾', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            toastId: 'notify-toast'
        });
    };

    const onSubmit = async (data) => {
        /* No need to escape script tags, emailJS handles it by escaping them automatically*/
        setLoading(true)
        try {
            const messageParams = {
                name: data.name,
                email: data.email,
                message: data.message
            };
            await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                messageParams,
                process.env.REACT_APP_USER_ID,
            );
            reset();
            toastifySuccess();
        }
        catch (e) {
            console.log(e);
        }
        setLoading(false);
    };


    return (
        <Container fluid={'md'}>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>

                    <div className="contact-form-card">

                        <h1 className="text-center">Questions? Jobs? Collaborations? Send me a message</h1>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <div className="fcf-form-group">
                                <label htmlFor="Name" className="fcf-label">Name <span className="asterisks">*</span></label>
                                <div className="fcf-input-group">
                                    <input type="text"
                                        name="name" className="fcf-form-control"
                                        required
                                        ref={register({
                                            required: { value: true, message: 'Please enter your name' },
                                            maxLength: {
                                                value: 30,
                                                message: 'Please use 30 characters or less'
                                            }
                                        })}
                                    />
                                    {errors.name && <span className='form-error'>{errors.name.message}</span>}
                                </div>
                            </div>

                            <div className="fcf-form-group">
                                <label htmlFor="Email" className="fcf-label">Email Address <span className="asterisks">*</span></label>
                                <div className="fcf-input-group">
                                    <input type="email" name="email"
                                        className="fcf-form-control"
                                        ref={register({
                                            required: true,
                                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                        })}
                                    />
                                    {errors.email && (
                                        <span className='form-error'>Please enter a valid email address</span>
                                    )}
                                </div>
                            </div>

                            <div className="fcf-form-group">
                                <label htmlFor="Message" className="fcf-label">Message <span className="asterisks">*</span></label>
                                <div className="fcf-input-group">
                                    <textarea
                                        name="message" className="fcf-form-control"
                                        rows="6" maxLength="1000"
                                        placeholder="max characters: 1000"
                                        ref={register({
                                            required: true, maxLength: 1000
                                        })}
                                    ></textarea>
                                    {errors.message && errors.message.type === "required" && <span className='form-error'>Please enter a message</span>}
                                    {errors.message && errors.message.type === "maxLength" && <span className='form-error'>Max length exceeded</span>}
                                </div>
                            </div>

                            <div className="fcf-form-group">
                                <Button type="submit" id="fcf-button"
                                    className="fcf-btn">
                                    {isLoading ? 'Loading…' : 'Send'}
                                </Button>
                            </div>
                        </form>

                    </div>
                    <ToastContainer />
                </Col>
            </Row>
        </Container>

    );
};

export default ContactForm;