import { Accordion, Button, Card, Container } from 'react-bootstrap'
import { ArrowDownCircleFill, Laptop, Book, ShieldExclamation, House, Phone, Bank, Globe } from 'react-bootstrap-icons'
import ScrollToTop from '../components/scrollToTop'
import { useRef } from 'react'
import { RESUME_DOWNLOAD_URL } from '../constants/urls'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/resume.css'

const Resume = () => {

  const workRef = useRef()
  const handleScrollDownClick = () => {
    workRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  return (
    <main>

      <section className="page-one">
        <div className="banner">
          <AnimationOnScroll animateIn='animate__flipInY'>
            <h1>Resume</h1>
            <Button href={RESUME_DOWNLOAD_URL}
              variant="outline-success" className="download-btn">
              Download Resume</Button>
          </AnimationOnScroll>
        </div>

        <div>
          <ArrowDownCircleFill className="scrollDown" onClick={handleScrollDownClick} color="#18d26e" size={48} />
        </div>
      </section>


      <span ref={workRef}></span>
      <ScrollToTop />


      <section className="page-two">
        <AnimationOnScroll animateIn='animate__zoomIn'
          animateOut='animate__zoomOut'>
          <div id="resume">
            <h1>WORK EXPERIENCE</h1>
            <p className='title-description'>I have worked in IT Consulting, Banking, Insurance, and Forensic Research.</p>
            <Container fluid={'md'}>
              <Accordion>
                <Card id="accordion-card">
                  <Card.Header className="card-header">
                    <Accordion.Toggle as={Button} variant="outline-success"
                      eventKey="0" id="accordion-btn">
                      View Details
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="card-body">

                      {/*WORK TIMELINE */}
                      <div className="timeline">
                        <div className="center-line">
                          <i className="scroll-icon"><House size={24} /></i>
                        </div>
                        <div className="row row-1">
                          <section>
                            <i className="icon fas fa-home"> <Globe size={24} /></i>
                            <div className="details">
                              <span className="title">Application Dev. Team Lead</span>
                              <span className="date">2022-Present</span>
                            </div>
                            <p className="company">Accenture, Lithuania</p>
                          </section>
                        </div>
                        <div className="row row-2">
                          <section>
                            <i className="icon"><Laptop size={24} /></i>
                            <div className="details">
                              <span className="title">Software Engineer</span>
                              <span className="date">2020-2022</span>
                            </div>
                            <p className="company">Freelance, Ghana</p>
                          </section>
                        </div>
                        <div className="row row-1">
                          <section>
                            <i className="icon"><Book size={24} /></i>
                            <div className="details">
                              <span className="title">Research Assistant</span>
                              <span className="date">2019</span>
                            </div>
                            <p className="company">Cybersecurity Centre, University of Hertfordshire</p>
                          </section>
                        </div>
                        <div className="row row-2">
                          <section>
                            <i className="icon"><ShieldExclamation size={24} /></i>
                            <div className="details">
                              <span className="title">Software Engineer</span>
                              <span className="date">2017-2018</span>
                            </div>
                            <p className="company">Insurance Collections Bureau, UK</p>
                          </section>
                        </div>
                        <div className="row row-1">
                          <section>
                            <i className="icon"><Bank size={24} /></i>
                            <div className="details">
                              <span className="title">MIS IT Support</span>
                              <span className="date">2015-2016</span>
                            </div>
                            <p className="company"><abbr title="Ghana Interbank Payment and Settlement Systems Limited">GHIPSS</abbr><span>, </span>Ghana</p>
                          </section>
                        </div>
                        <div className="row row-2">
                          <section>
                            <i className="icon"><Phone size={24} /></i>
                            <div className="details">
                              <span className="title">Mobile Developer (Part-time)</span>
                              <span className="date">2015-2016</span>
                            </div>
                            <p className="company">Bitberg Limited, Ghana</p>
                          </section>
                        </div>
                      </div>

                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Container>
          </div>

        </AnimationOnScroll>
      </section>

    </main>
  );
}

export default Resume;