import Image404 from "../assets/images/error-404-monochrome.svg";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/page404.css'
import { Link } from "react-router-dom";

const Page404 = () => {

    return (
        <main>
            <section className="page-centered">
                <AnimationOnScroll animateIn='animate__flipInY'>

                    <img className="img-error fluid"
                        alt="404 avatar"
                        loading="lazy"
                        width="400" height="320"
                        src={Image404} />

                    <p className="description">page not found</p>
                    <Link to="/" className="link">Go to home</Link>
                </AnimationOnScroll>

            </section>
        </main>
    );
}

export default Page404;