import React from 'react'
import { EmojiSmileFill } from 'react-bootstrap-icons'
import ContactForm from '../components/contactform'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../assets/css/contact.css'

export default function Contact() {

    return (
        <main>
            <section className="contact-page">
                <AnimationOnScroll animateIn='animate__zoomIn'>
                    <h1>Contact Me</h1>
                    <p>Don't be a stranger <EmojiSmileFill color="gold" /></p>
                    <ContactForm />
                </AnimationOnScroll>
            </section>
        </main>
    )
}
